var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full lg:w-[721px] mx-auto"},[_c('form-base',{ref:"form",on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var loading = ref.loading;
var success = ref.success;
var changed = ref.changed;
return [_c('nav',{staticClass:"flex items-center justify-between mb-24"},[_c('h1',{staticClass:"hidden lg:block text-32 text-900 font-bold"},[_vm._v("Personal settings")]),_c('div',{staticClass:" w-full lg:w-auto flex items-center"},[_c('div',{staticClass:"font-medium text-grey-700 mr-16 whitespace-nowrap opacity-0 transition-opacity",class:{ '!opacity-100': changed }},[_vm._v("Unsaved changes")]),_c('form-submit',{attrs:{"disabled":invalid,"loading":loading,"success":success}},[_vm._v("Save")])],1)]),_c('div',{staticClass:"grid grid-cols-1 lg:grid-cols-2 gap-24 mb-40"},[_c('div',{staticClass:"lg:col-span-2"},[_c('form-single-image-input',{attrs:{"type":"profile"},model:{value:(_vm.payload.media),callback:function ($$v) {_vm.$set(_vm.payload, "media", $$v)},expression:"payload.media"}})],1),_c('form-input',{attrs:{"label":"Voornaam","auto-focus":true,"placeholder":"Voornaam","rules":"required"},model:{value:(_vm.payload.first_name),callback:function ($$v) {_vm.$set(_vm.payload, "first_name", $$v)},expression:"payload.first_name"}}),_c('form-input',{attrs:{"label":"Naam","placeholder":"Naam","rules":"required"},model:{value:(_vm.payload.last_name),callback:function ($$v) {_vm.$set(_vm.payload, "last_name", $$v)},expression:"payload.last_name"}}),_c('form-input',{attrs:{"disabled":true,"label":"E-mailadres","name":"email","placeholder":"E-mailadres","type":"email","rules":"required|email"},model:{value:(_vm.payload.email),callback:function ($$v) {_vm.$set(_vm.payload, "email", $$v)},expression:"payload.email"}}),_c('form-error',{staticClass:"col-span-2",attrs:{"error-response":_vm.errorResponse}})],1)]}}])}),_c('form-base',{ref:"passwordResetForm",on:{"submit":_vm.resendPassword},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var loading = ref.loading;
var success = ref.success;
return [(!_vm.completed)?[_c('h2',{staticClass:"mb-8 text-20 font-bold text-grey-900"},[_vm._v("Wachtwoord")]),_c('div',{staticClass:"text-grey-600 mb-32"},[_vm._v("We sturen je de link om je wachtwoord te wijzigen per email.")]),_c('form-submit',{staticClass:"!w-auto !inline-flex mr-auto",attrs:{"disabled":invalid,"loading":loading,"success":success}},[_vm._v("Wachtwoord wijzigen")])]:[_c('h2',{staticClass:"mb-8 text-20 font-bold text-grey-900"},[_vm._v("Email verzonden")]),_c('div',{staticClass:"text-grey-600 mb-32"},[_vm._v("Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.")])],_c('form-error',{attrs:{"error-response":_vm.passwordResetErrorResponse}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }