


































import { Action, Getter } from "vuex-class";
import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit, FormSingleImageInput } from "@/components/forms";

@Component({
  components: {
    FormBase,
    FormInput,
    FormSingleImageInput,
    FormError,
    FormSubmit,
  },
})
export default class PageProfile extends Vue {
  @Getter("auth/me") account!: Account;

  @Action("auth/me") getMe!: (dontUseStorage: boolean) => Promise<Account>;

  @Action("account/update") updateAccount!: ({ payload, id }: { payload: AccountPayload; id: number }) => Promise<Account>;

  @Action("auth/passwordForgot") passwordForgot!: (payload: PasswordForgotPayload) => Promise<void>;

  @Ref() form!: FormClass;

  @Ref() passwordResetForm!: FormClass;

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  passwordResetErrorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  test = [];

  payload: AccountPayload = {
    first_name: "",
    last_name: "",
    email: "",
  };

  completed = false;

  private submit() {
    this.updateAccount({ payload: this.payload, id: parseInt(`${this.account.id}`, 10) })
      .then(() => this.getMe(true))
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse))
      .finally(() => this.form.reset());
  }

  private resendPassword() {
    this.passwordForgot({ email: this.account.email })
      .then(() => (this.completed = true))
      .catch((errorResponse: ErrorResponse) => (this.passwordResetErrorResponse = errorResponse))
      .finally(() => this.passwordResetForm.reset());
  }

  @Watch("account", { immediate: true })
  handleAccountChanged(account: Account) {
    this.payload.first_name = account.first_name;
    this.payload.last_name = account.last_name;
    this.payload.email = account.email;
    // @ts-ignore
    this.payload.media = account.media;
  }
}
